<template>
  <div id="Pay">
    <main>
      <div class="main-box">
        订单编号：
        <span>{{ downloadData.code }}</span>
      </div>
      <div style="margin: 40px 30px;">
        支付金额
        <span class="color">
          ￥
          <span>{{ data.coursePrice }}</span>
        </span>
      </div>
      <hr />
      <div style="margin: 40px 30px;">支付方式：</div>
      <div class="mode">
        <div class="weiPay">
          <svg-icon icon-class="weixinPay" style="font-size:24px;margin-right:4px;" />
          微信支付
        </div>
        <div class="aliPay" @click="getaliPay">
          <div style="margin-top:1px;margin-right:3px;">
            <svg-icon
              icon-class="zhifubao"
              style="color:#1296db;font-size:24px;margin-right:4px;"
            />
          </div>
          <div>支付宝支付</div>
        </div>
      </div>
      <div class="box">
        <div class="PayCode">
          <vue-qr
            :text="downloadData.qr + ''"
            :margin="0"
            colorDark="#000000"
            colorLight="#fff"
            :size="200"
          ></vue-qr>
          <div class="note">请打开手机微信，扫一扫完成支付</div>
        </div>
        <!-- <div class="img-note"><img src="#" alt="" /></div> -->
      </div>
    </main>
  </div>
</template>

<script>
import vueQr from 'vue-qr';
import API from '@/api';
// import cookies from '@/utils/cookies'

export default {
  name: 'Pay',
  data() {
    return {
      invoiceId: {
        id: '',
      }, // 订单状态参数
      downloadData: '',
      data: '',
      paramDetail: {
        // 页面数据参数
        id: '',
      },
      wx: {
        // 微信和支付宝支付参数
        courseId: '',
        courseQty: 1,
        priceUnit: '',
        id: '',
      },
      courseId: {
        // 传支付成功页面的courseId
        id: '',
      },
      PayState: '', // 支付状态
      aliUrl: '', // 支付宝支付路径
    };
  },
  components: {
    vueQr,
  },
  created() {
    this.getDetail();
    this.GetState();
  },
  methods: {
    // 获取课程信息
    getDetail() {
      this.paramDetail.id = this.$route.query.id;
      API.Detail(this.paramDetail).then((res) => {
        if (res.message.success === true) {
          this.data = res.message.data;
          this.wx.courseId = res.message.data.courseId;
          this.wx.priceUnit = res.message.data.coursePrice;
          this.getwx();
        }
      });
    },
    // 获取微信二维码
    getwx() {
      this.wx.id = this.$route.query.orderId;
      this.wx.courseId = this.$route.query.id;
      // if (cookies.get('token')) {
      API.wx(this.wx).then((res) => {
        this.downloadData = res.message.data;
        this.invoiceId.id = res.message.data.orderId;
        this.CheckState();
      });
      // }
    },
    // 支付宝支付
    getaliPay() {
      this.wx.id = this.invoiceId.id;
      this.wx.courseId = this.$route.query.id;
      API.AliPay(this.wx).then((res) => {
        if (res.message.success === true) {
          this.aliUrl = res.message.data;
          window.open(this.aliUrl);
        }
      });
    },
    // 判断支付状态
    CheckState() {
      API.state(this.invoiceId).then((res) => {
        if (res.message.success === true) {
          this.PayState = res.message.data.orderState;
        }
      });
    },
    // 循环判断支付状态
    GetState() {
      const tiemer = setInterval(() => {
        this.CheckState();
        if (this.PayState === 1) {
          clearInterval(tiemer);
          this.courseId.id = this.$route.query.id;
          this.$router.push({
            path: '/SuccessfulPay',
            query: {
              id: this.courseId.id,
              invoiceCode: this.downloadData.code,
              price: this.data.coursePrice,
              orderId: this.invoiceId.id,
            },
          });
        }
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  margin: auto;
  width: 1200px;
  height: 80vh;
}
.main-box {
  margin: 40px 30px;
}
.color {
  color: #f7b515;
  font-size: 30px;
}
.mode {
  display: flex;
  .weiPay {
    line-height: 60px;
    text-align: center;
    width: 200px;
    height: 60px;
    font-size: 18px;
    cursor: pointer;
    margin: 20px;
    border: 1px solid #47b411;
  }
  .aliPay {
    font-size: 18px;
    display: flex;
    line-height: 60px;
    justify-content: center;
    width: 200px;
    height: 60px;
    cursor: pointer;
    margin: 20px;
    border: 1px solid;
  }
  .weiPay:hover {
    border: 1px solid #47b411;
  }
  .aliPay:hover {
    border: 1px solid #1296db;
  }
}
.box {
  display: flex;
  .PayCode {
    margin-right: 35px;
    img {
      width: 230px;
      height: 230px;
    }
    .note {
      width: 250px;
      height: 30px;
      background: #47b411;
      color: #ffffff;
      font-size: 13px;
      text-align: center;
      line-height: 30px;
      margin-top: 30px;
    }
    .note1 {
      width: 250px;
      height: 30px;
      background: #1296db;
      color: #ffffff;
      font-size: 13px;
      text-align: center;
      line-height: 30px;
      margin-top: 30px;
    }
  }
  .img-note > img {
    width: 250px;
    height: 300px;
  }
}
</style>
